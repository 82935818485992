/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Mail as MailIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'

import useButtonStyles from 'components/buttonStyles'
import CourseContributionMenu from 'components/course_contribution/CourseContributionMenu'
import TooltipComponent from 'components/TooltipComponent'

export default function CourseContributionButton({ course, disabled = false }: { course: Course; disabled?: boolean }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useButtonStyles()
    const title = gettext('Course Contributions')

    return (
        <React.Fragment>
            {course && <CourseContributionMenu course={course} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />}
            <TooltipComponent title={title}>
                <Button
                    variant="contained"
                    className={clsx([classes.button, classes.bordered])}
                    aria-label={title}
                    onClick={event => {
                        setAnchorEl(event.currentTarget)
                    }}
                    disabled={disabled}
                >
                    <MailIcon />
                </Button>
            </TooltipComponent>
        </React.Fragment>
    )
}
