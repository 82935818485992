/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Bookmark as BookmarkIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import axios from 'axios'
import clsx from 'clsx'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import useStyles from 'components/buttonStyles'
import utils from 'components/react_components/utils'
import TooltipComponent from 'components/TooltipComponent'

export default function Bookmark({
    object,
    onBookmarkChanged = noop
}: {
    object: { bookmark?: number; id: number; model: string }
    onBookmarkChanged?: (...args: any) => any
}) {
    const [savingInProgress, setSavingInProgress] = useState(false)
    const classes = useStyles()

    const onBookmarkChangedCb = async () => {
        try {
            setSavingInProgress(true)
            if (object.bookmark) {
                await axios.delete(window.Urls['bookmarks:bookmark_detail'](object.bookmark))
                object = { ...object, bookmark: null }
            } else {
                const response = await axios.post(window.Urls['bookmarks:bookmark_list'](), {
                    contentId: object.id,
                    contentType: object.model
                })
                object = { ...object, bookmark: response.data.id }
            }
        } catch (error) {
            utils.defaultAjaxErrorHandler(error)()
        } finally {
            setSavingInProgress(false)
        }
        onBookmarkChanged(object)
    }

    let title
    if (savingInProgress) {
        title = gettext('Saving...')
    } else {
        title = object.bookmark ? gettext('Remove Bookmark') : gettext('Add Bookmark')
    }

    return (
        <TooltipComponent title={title}>
            <Button
                data-content-type={object.model}
                onClick={onBookmarkChangedCb}
                aria-label={title}
                disabled={savingInProgress}
                className={clsx([classes.button, classes.bordered])}
            >
                {object.bookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </Button>
        </TooltipComponent>
    )
}
