/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Group as GroupIcon, ThumbUp as ThumbUpIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Bookmark from 'components/bookmarks/Bookmark'
import useButtonStyles from 'components/buttonStyles'
import CourseDetailsModal from 'components/course/CourseDetailsModal'
import CourseActionsButton from 'components/course_actions/CourseActionsButton'
import CourseContributionButton from 'components/course_contribution/CourseContributionButton'
import StartButton from 'components/StartButton'
import TooltipComponent from 'components/TooltipComponent'
import { addOrUpdateCourse } from 'pages/my_content/redux/coursesSlice'

import ChangeCourseStatusButton from './ChangeCourseStatusButton'

const useStyles = makeStyles({
    container: { display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', width: '100%', gap: 2 },
    startButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '48%',
        height: '38px'
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        gap: 2
    }
})

export default function ButtonRow(props: {
    course: Course
    isAllowedToCreateCourseInvitations?: boolean
    userIsAuthenticated: boolean
    triggerMutation: (...args: any) => any
}) {
    const { course, userIsAuthenticated, triggerMutation } = props
    const [showCourseDetailsModal, setShowCourseDetailsModal] = useState(false)
    const dispatch = useDispatch()
    const buttonClasses = useButtonStyles()
    const classes = useStyles()

    const openCourseDetailsModal = () => {
        setShowCourseDetailsModal(true)
    }
    const closeCourseDetailsModal = () => {
        setShowCourseDetailsModal(false)
    }

    const confirmPublishMessage = interpolate(gettext('Are you sure you want to publish the course %s?'), [
        course.title
    ])
    const confirmApproveMessage = interpolate(gettext('Are you sure you want to approve the course %s?'), [
        course.title
    ])

    const showDetailsTitle = gettext('Show details')

    return (
        <div className={classes.container}>
            {showCourseDetailsModal && (
                <CourseDetailsModal
                    highlights={course.highlights}
                    courseId={course.id}
                    onHide={closeCourseDetailsModal}
                    userIsAuthenticated={userIsAuthenticated}
                    triggerMutation={triggerMutation}
                />
            )}
            <div className={classes.startButtonContainer}>
                <StartButton config={course.startButton} />
            </div>
            <div className={classes.actionButtons}>
                <CourseActionsButton course={course} disabled={!course.actions.editCourseDetails} />
                {course.status === 'draft' && 'userCanApproveCourse' in course && (
                    <ChangeCourseStatusButton
                        course={course}
                        okButtonText={gettext('Approve')}
                        action="approve"
                        buttonTooltip={gettext('Approve Course')}
                        iconType={<ThumbUpIcon />}
                        confirmModalMessage={confirmApproveMessage}
                        disabled={!course.userCanApproveCourse}
                    />
                )}
                {course.status === 'approved' && 'userCanPublishCourse' in course && (
                    <ChangeCourseStatusButton
                        course={course}
                        okButtonText={gettext('Publish')}
                        action="publish"
                        buttonTooltip={gettext('Publish Course')}
                        iconType={<GroupIcon />}
                        confirmModalMessage={confirmPublishMessage}
                        disabled={!course.userCanPublishCourse}
                    />
                )}
                <CourseContributionButton
                    {...props}
                    disabled={!course.actions.sendCourseInvitations && !course.actions.shareCourse}
                />
                {userIsAuthenticated && (
                    <Bookmark object={course} onBookmarkChanged={course => dispatch(addOrUpdateCourse(course))} />
                )}
                <TooltipComponent title={showDetailsTitle}>
                    <Button
                        className={clsx([buttonClasses.button, buttonClasses.bordered])}
                        aria-label={gettext('Show details')}
                        onClick={openCourseDetailsModal}
                    >
                        <i className="fa-solid fa-info fa-xl" />
                    </Button>
                </TooltipComponent>
            </div>
        </div>
    )
}
