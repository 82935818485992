/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Menu, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import pick from 'lodash/pick'
import React, { useState } from 'react'

import CourseContributionActions from 'components/course_contribution/CourseContributionActions'
import InviteAuthorsModal from 'components/course_contribution/InviteAuthorsModal'
import ManageCourseInstructorsModal from 'components/course_contribution/ManageCourseInstructorsModal'
import ShareCourseModal from 'components/course_contribution/ShareCourseModal'
import { useCourseActions } from 'hooks/data/courses'

const useStyles = makeStyles(theme => ({
    menu: {
        '& li': {
            color: theme.palette.secondary.main
        }
    }
}))

export default function CourseContributionMenu({
    course,
    anchorEl,
    onClose
}: {
    course: Course
    anchorEl?: Element
    onClose: () => void
}) {
    const [inviteAuthorsOpen, setInviteAuthorsOpen] = useState(false)
    const [shareCourseOpen, setShareCourseOpen] = useState(false)
    const [manageCourseInstructorsOpen, setManageCourseInstructorsOpen] = useState(false)

    return (
        <React.Fragment>
            {shareCourseOpen && <ShareCourseModal courseId={course.id} onHide={() => setShareCourseOpen(false)} />}
            {inviteAuthorsOpen && <InviteAuthorsModal course={course} onHide={() => setInviteAuthorsOpen(false)} />}
            {manageCourseInstructorsOpen && (
                <ManageCourseInstructorsModal course={course} onHide={() => setManageCourseInstructorsOpen(false)} />
            )}
            {anchorEl && (
                <CourseContributionMenuComponent
                    anchorEl={anchorEl}
                    course={course}
                    onClose={onClose}
                    setInviteAuthorsOpen={setInviteAuthorsOpen}
                    setShareCourseOpen={setShareCourseOpen}
                    setManageCourseInstructorsOpen={setManageCourseInstructorsOpen}
                />
            )}
        </React.Fragment>
    )
}

function CourseContributionMenuComponent({
    course,
    anchorEl,
    onClose,
    setInviteAuthorsOpen,
    setShareCourseOpen,
    setManageCourseInstructorsOpen
}: {
    course: { id: number }
    anchorEl: Element
    onClose: () => void
    setInviteAuthorsOpen: (open: boolean) => void
    setShareCourseOpen: (open: boolean) => void
    setManageCourseInstructorsOpen: (open: boolean) => void
}) {
    const open = Boolean(anchorEl)
    const classes = useStyles()
    let { data: courseActions } = useCourseActions(course.id)
    if (!courseActions) {
        return null
    }

    courseActions = pick(courseActions, ['sendCourseInvitations', 'shareCourse'])
    const menuIsEmpty = Object.values(courseActions).every(v => Boolean(v) === false)

    const menuProps = {
        className: classes.menu,
        onClick: onClose,
        open,
        anchorEl,
        onClose
    }
    if (menuIsEmpty) {
        return (
            <Menu {...menuProps}>
                <MenuItem>{gettext('No available actions')}</MenuItem>
            </Menu>
        )
    }
    return (
        <Menu {...menuProps}>
            <CourseContributionActions
                courseActions={courseActions}
                setInviteAuthorsOpen={setInviteAuthorsOpen}
                setShareCourseOpen={setShareCourseOpen}
                setManageCourseInstructorsOpen={setManageCourseInstructorsOpen}
            />
        </Menu>
    )
}
